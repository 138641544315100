@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('./fonts.css');


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @apply font-sans bg-zinc-100 text-zinc-800 px-4 sm:px-[24px] desktop:px-[38px] large:px-[68px] text-lg;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.form_step {
  @apply rounded w-full min-w-[150px] border p-2 gap-1 flex justify-start text-lg font-medium font-sans capitalize items-center whitespace-nowrap;
}

.selected_step {
  @apply text-white bg-blue-500;
}

.completed_step {
  @apply text-green-700 bg-green-100;
}

.default_step {
  @apply border-zinc-100;
}

.form_step_bar::-webkit-scrollbar,
.custom_scroll::-webkit-scrollbar,
.form-textarea::-webkit-scrollbar,
.jodit-wysiwyg::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.form_step_bar::-webkit-scrollbar-track,
.custom_scroll::-webkit-scrollbar-track,
.form-textarea::-webkit-scrollbar-track,
.jodit-wysiwyg::-webkit-scrollbar-track {
  background: #e4e4e7;
  border-radius: 5px;
}

.form_step_bar::-webkit-scrollbar-thumb,
.custom_scroll::-webkit-scrollbar-thumb,
.form-textarea::-webkit-scrollbar-thumb,
.jodit-wysiwyg::-webkit-scrollbar-thumb {
  background: #a1a1aa;
  border-radius: 5px;
}

.form_step_bar::-webkit-scrollbar-thumb:hover,
.custom_scroll::-webkit-scrollbar-thumb:hover,
.form-textarea::-webkit-scrollbar-thumb:hover,
.jodit-wysiwyg::-webkit-scrollbar-thumb:hover {
  background: #71717a;
}

.form_block {
  @apply bg-white p-4 rounded-lg flex flex-col gap-4 min-w-[250px] desktop:min-w-[300px] large:min-w-[400px] text-base leading-[21px];
}

.form_block_title {
  @apply text-zinc-800 text-[26px] leading-[33.8px] font-serif font-normal;
}

.form-input {
  @apply rounded border border-zinc-300 p-3 py-2 bg-white text-zinc-900 h-[38px];
}

.form-input:disabled {
  @apply pointer-events-none opacity-70;
}

.form-input-phone {
  @apply rounded border !border-zinc-300 !bg-white !text-zinc-900 !h-[38px];
}

.form-input-phone-button {
  @apply rounded border !border-zinc-300 !bg-white !text-zinc-900 border-r-zinc-300;
}

.form-input-phone-button:hover {
  @apply !border-zinc-400 outline-none !bg-white !rounded-l;
}

.form-input-phone:focus {
  @apply !border-zinc-400 outline-none;
}

.form-select {
  @apply rounded border !border-zinc-300 !bg-white text-zinc-900 h-[38px];
}

.form-select-focused {
  box-shadow: none !important;
  border-color: white !important;
  @apply !border-zinc-400 outline-none !shadow-none;
}

.form-input:focus {
  @apply border-zinc-400 outline-none;
}

.form_label {
  @apply text-zinc-800 font-normal;
}

.identification-image-upload-button {
  @apply border border-zinc-300 p-3 text-zinc-600 bg-white rounded border-dashed flex justify-center items-center gap-1 hover:bg-zinc-50 hover:border-zinc-400 focus:bg-zinc-100 focus:border-zinc-300;
}

.main_button {
  @apply py-[10px] px-[30px] bg-zinc-800 text-white font-serif font-semibold text-lg rounded disabled:opacity-40 hover:bg-zinc-900 hover:shadow-lg;
}

.main_button:disabled {
  @apply pointer-events-none
}

.error_field {
  @apply !border-red-500;
}

.error_label {
  @apply !text-red-500 font-medium;
}

.secondary_button {
  @apply py-[10px] px-[30px] bg-white text-black font-serif font-semibold text-lg rounded disabled:opacity-40 hover:shadow-lg;
}

.form-offer-item_label {
  @apply text-zinc-700 font-normal text-base;
}

.form-offer-item-select {
  @apply rounded border !border-zinc-300 !bg-white text-zinc-900 h-[38px];
}

.form-offer-item-select-focused {
  box-shadow: none !important;
  border-color: white !important;
  @apply !border-zinc-400 outline-none !shadow-none;
}

.form-textarea {
  @apply rounded border border-zinc-300 p-3 bg-white text-zinc-900 resize-none min-h-40 font-medium text-base;
}

.form-textarea:focus {
  @apply border-zinc-400 outline-none;
}

.form-textarea:disabled {
  @apply opacity-40;
}

.blue_button {
  @apply rounded px-3 bg-blue-100 text-base text-blue-500 hover:bg-blue-200 focus:bg-blue-300 disabled:opacity-50 disabled:pointer-events-none
}

.text_button {
  @apply text-black text-lg font-serif font-semibold py-3 px-5 hover:text-zinc-700 hover:underline underline-offset-4 disabled:opacity-50 disabled:pointer-events-none
}

.add_status_save {
  @apply text-black font-serif font-semibold text-lg rounded bg-white md:w-[220px] py-[10px] px-[30px] border border-black hover:bg-black hover:text-white hover:shadow-lg disabled:opacity-50 disabled:pointer-events-none
}

.grey_button {
  @apply bg-zinc-200 rounded flex items-center justify-center gap-2 w-[120px] h-[42px] text-base text-black hover:bg-zinc-300 focus:bg-zinc-400
}

.grey_button:disabled {
  @apply opacity-30 pointer-events-none
}

.email_form_file {
  @apply h-[42px] border border-zinc-300 py-4 px-3 flex justify-start items-center rounded w-full tabletSm:w-[220px]
}

.red_button {
  @apply rounded px-3 bg-red-100 text-base text-red-500 hover:bg-red-200 focus:bg-red-300 disabled:opacity-50 disabled:pointer-events-none
}