@font-face {
  font-family: 'Brandon Grotesque';
  src: url('../fonts/BrandonGrotesque-Bold.ttf') format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('../fonts/BrandonGrotesque-Medium.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('../fonts/BrandonGrotesque-Regular.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('../fonts/BrandonGrotesque-Light.ttf') format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Adobe Garamond Pro';
  src: url('../fonts/AGaramondPro-Regular.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Adobe Garamond Pro';
  src: url('../fonts/AGaramondPro-Semibold.ttf') format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Adobe Garamond Pro';
  src: url('../fonts/AGaramondPro-Italic.ttf') format("opentype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
